
















import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'

import Component from 'vue-class-component'
import Vue from 'vue'

@Component({
  components: {
    CenteredColumnLayout
  }
})
export default class EmptyBoardsInfo extends Vue {
}
