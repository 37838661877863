





























































import { InputSetups } from '@/mixins/input-setups'
import { successNotification } from "@/includes/services/NotificationService";

import { timezoneList } from 'piramis-base-components/src/logic/timizone-list'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'

@Component({
  data() {
    return {
      timezoneList,
    }
  }
})
export default class SetBotTokenToBoard extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  @Prop() btnIcon!: string

  @Prop() btnType!: string

  @Prop() btnClasses!: string | Array<string>

  @Prop() action!: 'create' | 'update'

  @Emit()
  isLoading(state: boolean): boolean {
    return state
  }

  isModalOpen = false

  newBoard = this.defaultCreateBoardModel()

  setBotTokenToBoard(): void {
    this.isModalOpen = false
    this.isLoading(true)

    this.$store.dispatch('createBoard', this.newBoard)
      .then((board) => {
        if (board) {
          if (this.action === 'create') {
            successNotification(this.$t('bot_token_successfully_created').toString())

            this.$router.push({
              name: 'Common_settings',
              params: {
                [EntityTypes.BOT_ID]: board.board.split('.')[0]
              }
            })
          } else {
            successNotification(this.$t('bot_token_successfully_changed').toString())
          }
        }
      })
      .finally(() => {
        this.isLoading(false)
      })
  }

  defaultTimezone() {
    return this.$i18n.locale === 'ru' ? 'Europe/Moscow' : 'UTC'
  }

  handleCancel(): void {
    this.isModalOpen = false
    this.newBoard = this.defaultCreateBoardModel()
  }

  defaultCreateBoardModel() {
    return {
      token: '',
      timezone: this.defaultTimezone()
    }
  }
}
