import { SlimChannelInfo } from '@/includes/types/Board.types'
import moment from 'moment'

export default class ChannelLicense {

    channel:SlimChannelInfo

    static LICENSE_TYPES = {
        '0': '',
        '1': 'Business'
    }

    constructor(channel:SlimChannelInfo) {
        this.channel = channel
    }

    get licenseType() {
        return ChannelLicense.LICENSE_TYPES[this.channel.license_type]
    }

    get licenseTime() {
        return moment(this.channel.license_time).format('L, HH:mm')
    }

    get isLicenseExpired() {
        return moment().isAfter(this.channel.license_time)
    }

    get isLicenseExpiring() {
        return !this.channel.licence_expired && moment(this.channel.license_time).diff(moment(), 'd') <= 3
    }
}